import React, {useState, useEffect, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import tw, {styled} from 'twin.macro';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {TourContext} from '../helpers/tour/TourContext';

import Header from 'components/headers/light';
import {PageContainer, ContentWithPaddingXl} from 'components/items/Layouts';
import {GreenButton, OptionsButton, PrimaryButton} from 'components/items/Buttons';
import {LoadingContainer, PageLoading} from 'components/items/Loading';
import {
  DashboardHeading,
  ErrorHeading,
  HighlightedText,
  SmallSectionHeading,
  Subheading,
} from 'components/items/Headings';
import {InputField} from 'components/forms/InputField';
import {Modal} from 'components/items/Modals';

import {IsLoggedIn} from 'helpers/api/auth/auth';
import {getTeamInfo, updateApiKey} from 'helpers/api/firestore/Teams';
import {
  checkSubscriptionStatus,
  createStripeCheckoutSession,
  getSubscriptionStatusMessage,
  getSubscriptionStatusStatus,
  manageTeamSubscription,
  updateTeamSubscription,
  tiers,
} from 'helpers/api/functions/Stripe';
import SubscriptionStatus from 'components/items/SubscriptionStatus';
import {CurrencyToggleComponent} from 'components/items/CurrencyToggle';
import {CollapsibleSection} from 'components/items/CollapsibleSection';
import {SubscriptionStatusBanner} from 'components/items/SubscriptionStatusBanner';

const SubscriptionCard = styled.div`
  ${tw`
    border rounded-lg p-6 
    shadow-md 
    flex flex-col 
    justify-between 
    hover:shadow-xl 
    transition-all 
    duration-300
    mb-6
  `}
`;

const ConfigForm = styled.form`
  ${tw`
    bg-white 
    p-6 
    w-full 
  `}
`;

export default () => {
  const navigate = useNavigate();
  const [user] = IsLoggedIn();
  const [loading, setLoading] = useState(true);
  const [currentTeam, setCurrentTeam] = useState(null);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [APIKeyModal, setAPIKeyModal] = useState(false);
  const [currency, setCurrency] = useState('usd');
  const functions = getFunctions();
  const {run, stepIndex, setRun, setStepIndex} = useContext(TourContext);
  const [continueTour, setContinueTour] = useState(false);
  const [error, setError] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const validationSchema = Yup.object().shape({
    apiKey: Yup.string()
      .required('API Key required.')
      .matches(/^pk_/, 'API Key must start with "pk_"'),
  });

  useEffect(() => {
    const fetchTeamAndSubscriptionData = async () => {
      try {
        const team = await getTeamInfo(user.uid);
        setCurrentTeam(team);

        const subscriptionResult = await checkSubscriptionStatus(team.id);
        setCurrentSubscription(subscriptionResult);

        // Determine currency based on current subscription
        if (subscriptionResult.planType?.includes('gbp')) {
          setCurrency('gbp');
        }

        setLoading(false);
      } catch (error) {
        setError('Failed to fetch subscription data. Please try again or contact support.');
        setShowErrorModal(true);
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    if (user) {
      fetchTeamAndSubscriptionData();
    }
  }, [user]);

  useEffect(() => {
    if (run && loading) {
      setRun(false);
      setContinueTour(true);
    }
    if (continueTour && !loading) {
      setContinueTour(false);
      setRun(true);
    }
  }, [loading]);

  const handleSubscriptionUpgrade = async tierId => {
    try {
      setLoading(true);
      // Check if user has an active subscription that's not a trial
      if (currentSubscription?.status === 'active' && !currentSubscription?.trial) {
        await updateTeamSubscription(tierId, currentTeam.id);
      } else {
        await createStripeCheckoutSession(tierId, currentTeam.id);
      }
    } catch (error) {
      console.error('Subscription upgrade error:', error);
      setError('Failed to process subscription upgrade. Please try again or contact support.');
      setShowErrorModal(true);
      setLoading(false);
    }
  };

  const handleManageSubscription = async () => {
    try {
      setLoading(true);
      await manageTeamSubscription(currentTeam.id);
    } catch (error) {
      console.error('Subscription management error:', error);
      setError('Failed to access subscription management. Please try again or contact support.');
      setShowErrorModal(true);
      setLoading(false);
    }
  };

  const handleSubmitAPIKey = async (values, {setSubmitting}) => {
    try {
      setLoading(true);
      await updateApiKey(user.uid, values.apiKey.trim());
      setAPIKeyModal(true);
      setSubmitting(false);
      setLoading(false);
    } catch (error) {
      console.error('API Key update error:', error);
      setError('Failed to update API Key. Please try again or contact support.');
      setShowErrorModal(true);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <PageContainer>
        <Header isLoggedIn={true} />
        <LoadingContainer>
          <PageLoading />
        </LoadingContainer>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Header isLoggedIn={true} />
      <SubscriptionStatusBanner subscriptionStatus={currentSubscription} />

      <ContentWithPaddingXl>
        <Modal
          isOpen={APIKeyModal}
          modalHeading={'API Key Set'}
          modalBody={<Subheading>Your API Key has been set, ready to go!</Subheading>}
          modalButtons={[
            {
              text: 'Continue',
              action: () => {
                setAPIKeyModal(false);
                navigate('/dashboard');
              },
              buttonTag: OptionsButton,
            },
          ]}
        />
        <Modal
          isOpen={showErrorModal}
          modalHeading="Error"
          modalBody={
            <div className="text-center text-red-500">
              <ErrorHeading>{error}</ErrorHeading>
            </div>
          }
          modalButtons={[
            {
              text: 'Close',
              action: () => {
                setShowErrorModal(false);
                setError(null);
              },
              buttonTag: OptionsButton,
            },
          ]}
          onClose={() => {
            setShowErrorModal(false);
            setError(null);
          }}
        />
        <div className="w-full justify-items-center">
          <DashboardHeading>My Account</DashboardHeading>
        </div>
        <SubscriptionStatus
          status={currentSubscription.status}
          message={getSubscriptionStatusMessage(
            currentSubscription.status,
            currentSubscription?.trialEnd
          )}
        />
        <div className="md:flex md:justify-between">
          <SmallSectionHeading>
            Brand: <HighlightedText>{currentTeam.name}</HighlightedText>
          </SmallSectionHeading>
          <SmallSectionHeading>
            Your Current Plan:{' '}
            <HighlightedText>{currentSubscription?.planType || 'Unsubscribed'}</HighlightedText>
          </SmallSectionHeading>
        </div>
        <CollapsibleSection
          title="Subscription Plan"
          id={'subscription-plan-collapsible'}
          initiallyExpanded={run || continueTour}
        >
          <div className="flex justify-center">
            <CurrencyToggleComponent currency={currency} onCurrencyChange={setCurrency} />
          </div>

          <div className="grid md:grid-cols-3 gap-6">
            {tiers[currency].map(tier => (
              <SubscriptionCard key={tier.id}>
                <div>
                  <h3 className="text-xl font-bold mb-4">{tier.name}</h3>
                  <p className="text-2xl font-semibold mb-4">
                    {tier.priceMonthly} {tier.priceMonthly !== 'Contact Us' && '/month'}
                  </p>
                  <ul className="space-y-2 mb-4">
                    {tier.features.map(feature => (
                      <li key={feature} className="flex items-center">
                        <span className="mr-2">✓</span> {feature}
                      </li>
                    ))}
                  </ul>
                </div>
                {tier.priceMonthly !== 'Contact Us' && (
                  <>
                    {currentSubscription?.planType === tier.id ? (
                      <GreenButton
                        onClick={() => handleSubscriptionUpgrade(tier.id)}
                        disabled={true}
                      >
                        Current plan
                      </GreenButton>
                    ) : (
                      <PrimaryButton onClick={() => handleSubscriptionUpgrade(tier.id)}>
                        Select
                      </PrimaryButton>
                    )}
                  </>
                )}
              </SubscriptionCard>
            ))}
          </div>
          {currentSubscription.stripeCustomerId && (
            <div className="text-center mb-6">
              <OptionsButton onClick={handleManageSubscription}>
                Manage My Subscription
              </OptionsButton>
            </div>
          )}
        </CollapsibleSection>

        <CollapsibleSection
          title="API Configuration"
          initiallyExpanded={run || continueTour}
          id={'api-config-collapsible'}
        >
          <Formik
            initialValues={{apiKey: ''}}
            validationSchema={validationSchema}
            onSubmit={handleSubmitAPIKey}
          >
            {({handleSubmit, isValid, touched}) => (
              <ConfigForm onSubmit={handleSubmit}>
                <InputField
                  name="apiKey"
                  label="API Key"
                  tooltipContent={
                    '<div>Create a private API key on Klaviyo. On Klaviyo, navigate to the following:</div><div>Settings > API keys > Create Private API Key > Full Access Key</div>'
                  }
                />
                <div className="flex justify-center mt-6">
                  <OptionsButton
                    type="submit"
                    disabled={!isValid || Object.keys(touched).length === 0}
                  >
                    Submit
                  </OptionsButton>
                </div>
              </ConfigForm>
            )}
          </Formik>
        </CollapsibleSection>
      </ContentWithPaddingXl>
    </PageContainer>
  );
};
