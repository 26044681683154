import React from 'react';
import tw, {styled} from 'twin.macro';

const CurrencyToggle = styled.div`
  ${tw`
    flex 
    justify-center 
    items-center 
    mb-6 
    rounded-full 
    p-1 
    max-w-xs 
  `}
`;

const ToggleButton = styled.button`
  ${tw`
    px-4 
    py-2 
    rounded-full 
    text-sm 
    font-semibold 
    transition-colors 
    duration-300
  `}
  ${props =>
    props.active
      ? tw`bg-primary-500 text-white`
      : tw`bg-transparent text-gray-600 hover:bg-gray-200`}
`;

export const CurrencyToggleComponent = ({currency, onCurrencyChange}) => {
  return (
    <CurrencyToggle>
      <ToggleButton active={currency === 'usd'} onClick={() => onCurrencyChange('usd')}>
        USD
      </ToggleButton>
      <ToggleButton active={currency === 'gbp'} onClick={() => onCurrencyChange('gbp')}>
        GBP
      </ToggleButton>
    </CurrencyToggle>
  );
};
