import {functions} from '../../../configuration/firebase/Firebase';
import {getCurrentTeam} from '../firestore/Teams';
import {httpsCallable} from 'firebase/functions';

export async function fetchTemplate(uid, template_id) {
  const team = await getCurrentTeam(uid);
  const getTemplate = httpsCallable(functions, 'template_info_service');

  try {
    const result = await getTemplate({team_id: team, template_id: template_id});
    return result.data;
  } catch (error) {
    console.error('Error calling template_info_service:', error);
    throw error;
  }
}
