import {getSubscriptionStatusMessage} from 'helpers/api/functions/Stripe';
import React from 'react';
import tw, {styled} from 'twin.macro';

const SubscriptionBanner = styled.div`
  ${tw`
    w-full 
    py-2 
    text-center 
    text-white 
    text-sm 
    font-medium
    fixed 
    left-0 
    top-0
    z-50
  `}
  background-color: ${props =>
    props.status === 'trialing' ? '#FFA500' : props.isActive ? '#4CAF50' : '#FF0000'};
`;

export const SubscriptionStatusBanner = ({subscriptionStatus}) => {
  if (!subscriptionStatus || subscriptionStatus.status == 'active') return null;

  let bannerText = getSubscriptionStatusMessage(
    subscriptionStatus.status,
    subscriptionStatus.trialEnd
  );

  return (
    <SubscriptionBanner status={subscriptionStatus.status} isActive={subscriptionStatus.isActive}>
      {bannerText}
    </SubscriptionBanner>
  );
};
