import React, {useContext, useEffect, useState} from 'react';
import tw, {styled} from 'twin.macro';
import {useNavigate} from 'react-router-dom';
import {getFunctions, httpsCallable} from 'firebase/functions';

// Component imports
import Header from 'components/headers/light';
import ExperimentTable from 'components/experiment/ExperimentTable';
import {ContentWithPaddingXl, PageContainer} from 'components/items/Layouts';
import {DashboardHeading, HighlightedText, MediumHeading} from 'components/items/Headings';
import {OptionsButton} from 'components/items/Buttons';
import {LoadingContainer, PageLoading} from 'components/items/Loading';
import SimpleCustomSelectMenu from '../components/forms/SimpleCustomSelectMenu';
import InfoTooltip from '../helpers/tour/InfoTooltip';

// Helper imports
import {IsLoggedIn} from 'helpers/api/auth/auth';
import {getTeamInfo, getUsersTeams} from '../helpers/api/firestore/Teams';
import {getActiveExperimentsByTeam} from '../helpers/api/firestore/Experiments';
import {TourContext} from '../helpers/tour/TourContext';
import {SubscriptionStatusBanner} from 'components/items/SubscriptionStatusBanner';
import {checkSubscriptionStatus} from 'helpers/api/functions/Stripe';

// Styled Components
const ModalOverlay = tw.div`
  fixed inset-0 bg-black bg-opacity-50 
  flex items-center justify-center 
  z-50 p-4
`;

const ModalContainer = tw.div`
  bg-white 
  p-6 sm:p-8 
  rounded-lg 
  shadow-xl 
  w-full 
  max-w-md 
  mx-auto 
  space-y-4
`;

const ResponsiveInput = styled.input`
  ${tw`
    w-full 
    px-3 py-2 
    border border-gray-300 
    rounded-md 
    shadow-sm 
    focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500
    text-sm sm:text-base
  `}
`;

const ResponsiveButton = styled.button`
  ${tw`
    w-full sm:w-auto
    px-4 py-2 
    rounded-md 
    text-white 
    bg-primary-600 
    hover:bg-primary-700 
    focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2
    transition duration-300 
    disabled:opacity-50 disabled:cursor-not-allowed
    text-sm sm:text-base
  `}
`;

const FlexResponsiveContainer = tw.div`
  flex flex-col sm:flex-row 
  items-center 
  justify-between 
  space-y-2 sm:space-y-0 sm:space-x-2
`;

const ResponsiveHeading = tw.h2`
  text-lg sm:text-xl 
  md:text-2xl 
  font-bold 
  text-gray-800 
  text-center sm:text-left
`;

const ResponsiveParagraph = tw.p`
  text-sm sm:text-base 
  text-gray-600 
  text-center sm:text-left 
  mb-4
`;

const CreateButtonContainer = tw.button`w-full flex justify-center text-primary-500`;
const StartTourButton = tw.button`underline hocus:text-primary-500`;
const InlineContainer = tw.div`inline-flex`;

const Dashboard = () => {
  const navigate = useNavigate();
  const [user] = IsLoggedIn();

  // State management
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  const [loading, setLoading] = useState(true);
  const [experiments, setExperiments] = useState([]);
  const [newTeamName, setNewTeamName] = useState('');
  const [teamCreationError, setTeamCreationError] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  // Tour-related state
  const {run, stepIndex, setRun, setStepIndex} = useContext(TourContext);
  const [continueTour, setContinueTour] = useState(false);

  const functions = getFunctions();

  // Effect to load subscription status and initial team data
  useEffect(() => {
    const loadInitialData = async () => {
      if (!selectedTeam) {
        const team = await getTeamInfo(user.uid);
        if (team) {
          setSelectedTeam(team);
          const userTeams = await getUsersTeams(user.uid);
          setTeams(userTeams);

          // Check subscription status
          try {
            const status = await checkSubscriptionStatus(team.id);
            setSubscriptionStatus(status);
          } catch (error) {
            console.error('Failed to fetch subscription status', error);
          }
        }
        setLoading(false);
      }

      if (selectedTeam) {
        const activeExperiments = await getActiveExperimentsByTeam(selectedTeam.id);
        setExperiments(activeExperiments);
      }
    };

    loadInitialData();
  }, [selectedTeam, user]);

  // Effect to handle tour state
  useEffect(() => {
    if (run && loading) {
      setRun(false);
      setContinueTour(true);
    }
    if (continueTour && !loading && selectedTeam) {
      setContinueTour(false);
      setRun(true);
    }
  }, [loading, selectedTeam]);

  // Handlers
  const handleTeamChange = e => {
    const team = teams.find(t => t.name === e.target.value);
    setSelectedTeam(team);
    localStorage.setItem('selectedTeam', team.id);
  };

  const handleCreateTeam = async () => {
    setLoading(true);

    if (!newTeamName.trim()) {
      setTeamCreationError('Team name cannot be empty');
      return;
    }

    try {
      const createTeamFunc = httpsCallable(functions, 'create_team');
      const res = await createTeamFunc({teamName: newTeamName.trim()});

      if (res.data.teamId) {
        localStorage.setItem('selectedTeam', res.data.teamId);

        const team = await getTeamInfo(user.uid);
        if (team) {
          setSelectedTeam(team);
          const userTeams = await getUsersTeams(user.uid);
          setTeams(userTeams);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error('Error creating team:', error);
      setTeamCreationError(error.message || 'Failed to create team');
    }
  };

  // Modify create experiment button to check subscription
  const handleCreateExperiment = () => {
    if (!subscriptionStatus?.isActive) {
      navigate('/my-account');
      return;
    }
    navigate('/create-experiment');
  };

  // Render loading state
  if (loading) {
    return (
      <PageContainer>
        <Header isLoggedIn={true} />
        <LoadingContainer>
          <PageLoading />
        </LoadingContainer>
      </PageContainer>
    );
  }

  // Render team creation modal if no team is selected
  if (!selectedTeam) {
    return (
      <PageContainer>
        <Header isLoggedIn={true} />
        <ModalOverlay>
          <ModalContainer>
            <ResponsiveHeading>Which Brand Will You Be Managing?</ResponsiveHeading>
            <ResponsiveParagraph>
              You need to provide a name for your account to get started with experiments, ideally
              this will be the brand name.
            </ResponsiveParagraph>
            <ResponsiveInput
              type="text"
              value={newTeamName}
              onChange={e => {
                setNewTeamName(e.target.value);
                setTeamCreationError('');
              }}
              placeholder="Enter team name"
            />
            {teamCreationError && <p tw="text-red-500 text-sm text-center">{teamCreationError}</p>}
            <FlexResponsiveContainer>
              <ResponsiveButton onClick={handleCreateTeam} disabled={!newTeamName.trim()}>
                Create Team
              </ResponsiveButton>
            </FlexResponsiveContainer>
          </ModalContainer>
        </ModalOverlay>
      </PageContainer>
    );
  }

  // Main dashboard view
  return (
    <PageContainer>
      <Header isLoggedIn={true} />
      <SubscriptionStatusBanner subscriptionStatus={subscriptionStatus} />

      <ContentWithPaddingXl id={'dashboard-body'}>
        {' '}
        <div className="w-full justify-items-center">
          <DashboardHeading>Experiments</DashboardHeading>
        </div>
        {/* Team selection section */}
        {teams.length > 1 ? (
          <SimpleCustomSelectMenu
            htmlId={'team'}
            label={'Select Brand'}
            value={selectedTeam.name}
            options={teams.map(team => team.name)}
            onChange={handleTeamChange}
          />
        ) : (
          <InlineContainer>
            Manage multiple Klaviyo accounts?
            <InfoTooltip
              id={'multiple-teams'}
              content={
                'Please get in touch with us on hello@mailbandit.ai and we can provide you with a bespoke setup.'
              }
            />
          </InlineContainer>
        )}
        <div className="my-10">
          {/* Experiments section */}
          <MediumHeading>
            You have&nbsp;
            <HighlightedText>{experiments.length}</HighlightedText> &nbsp;experiment
            {experiments.length > 1 ? 's' : ''} running
          </MediumHeading>
          <ExperimentTable listOfExperiments={experiments} />

          {/* Action buttons */}
          <CreateButtonContainer>
            <OptionsButton onClick={handleCreateExperiment} id="create-experiment">
              {subscriptionStatus?.isActive
                ? 'Create New Experiment'
                : 'Upgrade To Create Experiments'}
            </OptionsButton>
          </CreateButtonContainer>
        </div>
        <StartTourButton
          onClick={() => {
            setStepIndex(0);
            setRun(true);
          }}
        >
          Show me around
        </StartTourButton>
      </ContentWithPaddingXl>
    </PageContainer>
  );
};

export default Dashboard;
