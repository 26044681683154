import {functions} from '../../../configuration/firebase/Firebase';
import {getCurrentTeam} from '../firestore/Teams';
import {httpsCallable} from 'firebase/functions';

export async function fetchAvailableMetrics(uid) {
  const team = await getCurrentTeam(uid);
  const getMetrics = httpsCallable(functions, 'metrics_info_service');

  try {
    const result = await getMetrics({team_id: team});
    return result.data;
  } catch (error) {
    console.error('Error calling metrics_info_service:', error);
    throw error;
  }
}
