// services/subscriptionService.js
import {getFunctions, httpsCallable} from 'firebase/functions';
import {loadStripe} from '@stripe/stripe-js';

// Initialize Stripe with environment-specific key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
export const SubscriptionStatus = {
  TRIALING: 'trialing', // Stripe status for trial period
  ACTIVE: 'active', // Stripe status for active subscription
  PAST_DUE: 'past_due', // Stripe status for failed payment
  CANCELED: 'canceled', // Stripe status for canceled subscription
  INCOMPLETE: 'incomplete', // Stripe status for incomplete subscription
  UNPAID: 'unpaid', // Stripe status for unpaid subscription
};

export const tiers = {
  usd: [
    {
      name: 'Freelancer',
      id: 'freelancer-usd',
      priceMonthly: '$129.99',
      features: [
        'Unlimited Email Variations',
        'Test Up To 15 Workflows',
        'Chat + Email + Phone Support',
        'Unlimited Users',
        'Up To 5 Members Of Your Team',
      ],
    },
    {
      name: 'Startup',
      id: 'startup-usd',
      priceMonthly: '$199.99',
      mostPopular: true,
      features: [
        'Unlimited Email Variations',
        'Test Up To 50 Workflows',
        'Chat + Email + Phone Support',
        'Unlimited Users',
        'Up To 10 Members Of Your Team',
      ],
    },
    {
      name: 'Enterprise',
      id: 'enterprise-usd',
      priceMonthly: 'Contact Us',
      features: [
        'Unlimited Email Variations',
        'Unlimited Workflows',
        'Chat + Email + Phone Support',
        'Unlimited Users',
        'Unlimited Members Of Your Team',
      ],
    },
  ],
  gbp: [
    {
      name: 'Freelancer',
      id: 'freelancer-gbp',
      priceMonthly: '£99.99',
      features: [
        'Unlimited Email Variations',
        'Test Up To 15 Workflows',
        'Chat + Email + Phone Support',
        'Unlimited Users',
        'Up To 5 Members Of Your Team',
      ],
    },
    {
      name: 'Startup',
      id: 'startup-gbp',
      priceMonthly: '£149.99',
      mostPopular: true,
      features: [
        'Unlimited Email Variations',
        'Test Up To 50 Workflows',
        'Chat + Email + Phone Support',
        'Unlimited Users',
        'Up To 10 Members Of Your Team',
      ],
    },
    {
      name: 'Enterprise',
      id: 'enterprise-gbp',
      priceMonthly: 'Contact Us',
      features: [
        'Unlimited Email Variations',
        'Unlimited Workflows',
        'Chat + Email + Phone Support',
        'Unlimited Users',
        'Unlimited Members Of Your Team',
      ],
    },
  ],
};

export const createStripeCheckoutSession = async (planType, teamId) => {
  try {
    const functions = getFunctions();
    const initiateTeamSubscription = httpsCallable(functions, 'initiate_team_subscription');
    const {data} = await initiateTeamSubscription({planType, teamId});

    if (!data.sessionId) {
      throw new Error('Failed to create checkout session');
    }

    const stripe = await stripePromise;
    const {error} = await stripe.redirectToCheckout({
      sessionId: data.sessionId,
    });

    if (error) {
      throw new Error(error.message);
    }
  } catch (error) {
    console.error('Error creating checkout session:', error);
    throw error;
  }
};

export const manageTeamSubscription = async teamId => {
  try {
    const functions = getFunctions();
    const manageSubscription = httpsCallable(functions, 'manage_team_subscription');
    const response = await manageSubscription({teamId: teamId});

    if (!response.data.portalUrl) {
      throw new Error('Failed to create portal session');
    }
    window.location.href = response.data.portalUrl;
  } catch (error) {
    console.error('Subscription management error:', error);
    throw error;
  }
};

export const checkSubscriptionStatus = async teamId => {
  try {
    const functions = getFunctions();
    const checkStatus = httpsCallable(functions, 'check_subscription_status');
    const response = await checkStatus({teamId: teamId});

    if (!response.data.success) {
      throw new Error('Failed to fetch subscription status');
    }

    if (response.data.status == 'trialing') {
      return {
        isActive: response.data.isActive,
        status: response.data.status,
        planType: null,
        trialEnd: response.data.trialEnd,
      };
    }

    return {
      isActive: response.data.isActive,
      status: response.data.status,
      planType: response.data.planType,
    };
  } catch (error) {
    console.error('Error checking subscription status:', error);
    throw error;
  }
};

export const getSubscriptionStatusMessage = (status, trialEndsAt = null) => {
  const jsTime = new Date(Date.parse(trialEndsAt));
  switch (status) {
    case SubscriptionStatus.TRIALING:
      const daysLeft = Math.ceil((jsTime - Date.now()) / (1000 * 60 * 60 * 24));
      return `Trial: ${daysLeft} days remaining. Upgrade to continue using all features.`;
    case SubscriptionStatus.PAST_DUE:
      return 'Your payment is past due. Please update your payment method to continue using all features.';
    case SubscriptionStatus.CANCELED:
      return 'Your subscription has been canceled. Upgrade to restore access to all features.';
    case SubscriptionStatus.INCOMPLETE:
      return 'Your subscription setup is incomplete. Please complete the payment process.';
    case SubscriptionStatus.UNPAID:
      return 'We were unable to process your payment. Please update your payment method.';
    default:
      return '';
  }
};

export const getSubscriptionStatusStatus = status => {
  switch (status) {
    case SubscriptionStatus.TRIALING:
      return `Status: Trial`;
    case SubscriptionStatus.PAST_DUE:
      return 'Status: Past due';
    case SubscriptionStatus.CANCELED:
      return 'Status: Cancelled';
    case SubscriptionStatus.INCOMPLETE:
      return 'Status: Incomplete';
    case SubscriptionStatus.UNPAID:
      return 'Status: Unpaid';
    case SubscriptionStatus.ACTIVE:
      return 'Status: Active';
    default:
      return '';
  }
};

export const updateTeamSubscription = async (planType, teamId) => {
  try {
    const functions = getFunctions();
    const updateSubscription = httpsCallable(functions, 'update_team_subscription');
    const response = await updateSubscription({planType, teamId});

    if (!response.data.portalUrl) {
      throw new Error('Failed to create update session');
    }

    window.location.href = response.data.portalUrl;
  } catch (error) {
    console.error('Subscription update error:', error);
    throw error;
  }
};
