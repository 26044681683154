import React from 'react';
import tw from 'twin.macro';
import InfoTooltip from '../../helpers/tour/InfoTooltip';

const Container = tw.div`flex flex-col w-full`;
const Label = tw.label`text-sm font-semibold leading-6 text-gray-900`;
const Select = tw.select`
  rounded-md py-2 pl-3 pr-10 text-gray-900
  ring-1 ring-gray-300 hocus:ring-2 hocus:ring-gray-500
  sm:text-sm sm:leading-6 size-fit
`;

export default ({label = 'test', options = [], htmlId, value, onChange, tooltipContent}) => {
  const InlineContainer = tw.div`inline-flex`;

  return (
    <Container>
      <Label htmlFor={htmlId}>
        <InlineContainer>
          {label}
          {tooltipContent && <InfoTooltip id={'tooltip-' + htmlId} content={tooltipContent} />}
        </InlineContainer>
      </Label>
      <Select id={htmlId} name={htmlId} value={value} onChange={onChange}>
        {options.map(option => {
          return <option>{option}</option>;
        })}
      </Select>
    </Container>
  );
};
