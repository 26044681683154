import {Line} from 'react-chartjs-2';
import React from 'react';

export default ({dateLabelsOverTime, successOverTime, ylabel}) => {
  return (
    <Line
      data={{
        labels: dateLabelsOverTime.map(d => {
          return (
            d.getDate().toString().padStart(2, '0') +
            '/' +
            (d.getMonth() + 1).toString().padStart(2, '0') +
            '/' +
            d.getFullYear()
          );
        }),
        datasets: successOverTime.map((dataset, index) => ({
          ...dataset,
          tension: 0.2,
          pointRadius: 0,
          pointHoverRadius: 6,
          borderWidth: 2.5,
          fill: false,
        })),
      }}
      options={{
        interaction: {
          intersect: false,
          mode: 'index',
        },
        responsive: true,
        spanGaps: true,
        plugins: {
          legend: {
            display: true,
            position: 'top',
            labels: {
              boxWidth: 8,
              boxHeight: 8,
              padding: 15,
              color: '#4A5568',
              font: {
                size: 16,
                weight: '500',
                family: "'Inter', -apple-system, system-ui, sans-serif",
              },
            },
            title: {
              display: true,
              padding: {
                bottom: 10,
              },
              color: '#2D3748',
              font: {
                size: 14,
                weight: '600',
                family: "'Inter', -apple-system, system-ui, sans-serif",
              },
            },
          },
          tooltip: {
            enabled: true,
            backgroundColor: '#FFFFFF',
            titleColor: '#1A1A1A',
            bodyColor: '#4A5568',
            titleFont: {
              size: 13,
              weight: '600',
              family: "'Inter', -apple-system, system-ui, sans-serif",
            },
            bodyFont: {
              size: 12,
              family: "'Inter', -apple-system, system-ui, sans-serif",
            },
            padding: 12,
            cornerRadius: 6,
            displayColors: true,
            boxPadding: 6,
            borderColor: 'rgba(0, 0, 0, 0.1)',
            borderWidth: 1,
            usePointStyle: true,
            callbacks: {
              title: context => {
                return `Date: ${context[0].label}`;
              },
              label: context => {
                return `${context.dataset.label}: ${context.parsed.y.toLocaleString()}`;
              },
              labelColor: context => {
                return {
                  borderColor: context.dataset.borderColor,
                  backgroundColor: context.dataset.borderColor,
                  borderWidth: 2,
                };
              },
              labelTextColor: () => '#4A5568',
            },
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: ylabel,
              color: '#4A5568',
              font: {
                size: 16,
                weight: 'bold',
              },
            },
            ticks: {
              color: '#4A5568',
              font: {
                size: 14,
              },
            },
            grid: {
              color: 'rgba(74, 85, 104, 0.1)',
            },
          },
          x: {
            title: {
              display: true,
              text: 'Date',
              color: '#4A5568',
              font: {
                size: 16,
                weight: 'bold',
              },
            },
            ticks: {
              color: '#4A5568',
              font: {
                size: 14,
              },
              maxRotation: 0,
              minRotation: 0,
              callback: function (val, index) {
                return index % 4 === 0 ? this.getLabelForValue(val) : '';
              },
            },
            grid: {
              color: 'rgba(74, 85, 104, 0.1)',
            },
          },
        },
      }}
    />
  );
};
