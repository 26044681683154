import Hero from 'components/hero/BackgroundAsImageWithCenteredContent';
import LandingFooter from 'components/with_image/LandingFooter';
import LogoCloud from '../components/multicolumn/LogoCloud';
import ThreeColIconsDescription from '../components/multicolumn/ThreeColIconsDescription';
import TwoColHeaders from '../components/multicolumn/TwoColHeaders';
import TwoColHeadersImageDescription from '../components/multicolumn/TwoColHeadersImageDescription';

export default () => {
  return (
    <div>
      <Hero />
      <LogoCloud />
      <TwoColHeadersImageDescription />
      <TwoColHeaders />
      <ThreeColIconsDescription />
      <LandingFooter />
    </div>
  );
};
