import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {CheckCircleIcon} from '@heroicons/react/24/solid';
import {checkSubscriptionStatus} from '../helpers/api/functions/Stripe';
import {IsLoggedIn} from 'helpers/api/auth/auth';
import {getCurrentTeam} from 'helpers/api/firestore/Teams';
import {PageContainer} from 'components/items/Layouts';
import Header from 'components/headers/light';
import {PageLoading, LoadingContainer} from 'components/items/Loading';
import Modal from 'components/items/Modals';

export default function SubscriptionSuccess() {
  const navigate = useNavigate();
  const [currentUser] = IsLoggedIn();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const verifySubscription = async () => {
      try {
        await new Promise(resolve => setTimeout(resolve, 2000));
        const teamId = await getCurrentTeam(currentUser.uid);
        const status = await checkSubscriptionStatus(teamId);

        if (status.isActive) {
          setLoading(false);
        } else {
          setError(
            `Subscription activation pending. Please try again in a few minutes. Contact support@mailbandit.ai if this persists.`
          );
          setLoading(false);
        }
      } catch (err) {
        setError('Failed to verify subscription status');
        setLoading(false);
      }
    };

    verifySubscription();
  }, [currentUser]);

  const modalButtons = [
    {
      text: 'Go to Dashboard',
      action: () => navigate('/dashboard'),
      buttonTag: props => (
        <button
          {...props}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
        />
      ),
    },
  ];

  return (
    <PageContainer>
      <Header isLoggedIn={true} />

      {loading ? (
        <LoadingContainer>
          <PageLoading />
        </LoadingContainer>
      ) : (
        <Modal
          isOpen={true}
          modalHeading={error ? 'Subscription Error' : 'Subscription Activated!'}
          modalBody={
            error ? (
              <div className="text-red-600 mx-auto text-center">{error}</div>
            ) : (
              <div className="text-center">
                <CheckCircleIcon className="h-12 w-12 text-green-500 mx-auto" />
                <p className="mt-2 text-gray-600">
                  Thank you for your subscription. Your account has been successfully upgraded.
                </p>
              </div>
            )
          }
          modalButtons={modalButtons}
          onClose={() => navigate('/dashboard')}
        />
      )}
    </PageContainer>
  );
}
