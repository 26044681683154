import React, {useState} from 'react';

export const CollapsibleSection = ({title, children, initiallyExpanded = false, id}) => {
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);

  return (
    <div className="mb-8 border-b pb-6" id={id}>
      <div
        className="flex justify-between items-center cursor-pointer hover:bg-gray-100 p-2 rounded"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h2 className="text-xl font-bold">{title}</h2>
        <span>{isExpanded ? 'Collapse ▼' : 'Expand ▶'}</span>
      </div>
      {isExpanded && children}
    </div>
  );
};
