import React, {useEffect, useState} from 'react';
import Header from 'components/headers/light';
import {ContentWithPaddingXl, PageContainer} from 'components/items/Layouts';
import {
  DashboardHeading,
  HighlightedText,
  MediumHeading,
  Subheading,
} from 'components/items/Headings';
import tw from 'twin.macro';
import {RedButton} from 'components/items/Buttons';
import 'chart.js/auto';
import {LoadingContainer, PageLoading, ThreeDotsLoading} from 'components/items/Loading';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {IsLoggedIn} from 'helpers/api/auth/auth';
import {getExperiment, setExperimentInactive} from '../helpers/api/firestore/Experiments';
import {InlineText} from '../components/items/Typography';
import {Modal} from '../components/items/Modals';
import LineChart from '../components/analytics/LineCharts';
import StatCards from '../components/analytics/StatCards';
import Tabs from '../components/items/Tabs';
import TableStats from '../components/analytics/TableStats';
import {
  generateArrayOfDates,
  getLatestMetricDate,
  processBinaryMetrics,
  processContinuousMetrics,
} from '../helpers/metrics/MetricProcessing';
import {metric_mapping, Models} from '../configuration/metrics/Metrics';
import {getTeamInfo} from 'helpers/api/firestore/Teams';
import {checkSubscriptionStatus} from 'helpers/api/functions/Stripe';
import {SubscriptionStatusBanner} from 'components/items/SubscriptionStatusBanner';

export default () => {
  const ButtonContainer = tw.div` md:flex justify-center py-5 w-full`;
  const ThreeDotsLoadingContainer = tw.div`h-16 flex items-end`;
  const ChartContainer = tw.div`p-5 bg-white shadow-md rounded-lg h-full ring-1 ring-gray-300`;
  const RowContainer = tw.div`flex flex-row justify-between items-center`;

  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, userLoading] = IsLoggedIn();
  const [experiment, setExperiment] = useState(undefined);
  const [successChartData, setSuccessChartData] = useState(undefined);
  const [allocationChartData, setAllocationChartData] = useState(undefined);
  const [dateLabelsOverTime, setDateLabelsOverTime] = useState([]);
  const [isEarlyModelOpen, setIsEarlyModelOpen] = useState(false);
  const [statsCards, setStatsCards] = useState([]);
  const [tableStats, setTableStats] = useState([]);
  const [chartTab, setChartTab] = useState({});
  const [metricConfig, setMetricConfig] = useState({});
  const [experimentDeleted, setExperimentDeleted] = useState(false);
  const [error, setError] = useState(undefined);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  const navigate = useNavigate();

  /**
   * Sets the experiment to inactive and navigates the user to the dashboard page.
   */
  const deleteExperiment = () => {
    setLoading(true);
    setExperimentInactive(experiment.id).then(() => {
      setExperimentDeleted(true);
      setLoading(false);
    });
  };

  /**
   * useEffect to capture experiment id and retrieve experiment when page is first loading
   */
  useEffect(() => {
    let id = searchParams.get('experiment');

    const loadInitialData = async () => {
      try {
        const team = await getTeamInfo(user.uid);
        if (team) {
          // Check subscription status
          const subscriptionResult = await checkSubscriptionStatus(team.id);
          setSubscriptionStatus(subscriptionResult);
        }
      } catch (error) {
        console.error('Failed to fetch subscription status', error);
      }
    };

    loadInitialData();

    getExperiment(id)
      .then(exp => {
        setExperiment(exp);
        setDateLabelsOverTime(generateArrayOfDates(30, getLatestMetricDate()));
        return exp;
      })
      .then(experiment => {
        if (experiment !== undefined) {
          // open information modal for experiments less than 7 days
          if (experiment.numberOfDaysRan() < 7) {
            setIsEarlyModelOpen(true);
          }

          const matchedMetricConfig = metric_mapping[experiment.metricName];
          const processFunction =
            matchedMetricConfig.model === Models.TSBinary
              ? processBinaryMetrics
              : processContinuousMetrics;
          processFunction(experiment, matchedMetricConfig).then(
            ([successChartData, allocationChartData, statsCards, tableStats]) => {
              setMetricConfig(matchedMetricConfig);
              setSuccessChartData(successChartData);
              setAllocationChartData(allocationChartData);
              setStatsCards(statsCards);
              setTableStats(tableStats);

              setChartTab({
                name: 'Success over time',
                data: successChartData,
                yLabel: matchedMetricConfig.labels.success_ylabel,
              });
              setLoading(false);
            }
          );
        }
      })
      .catch(e => {
        setError({heading: 'Error', body: e.toString()});
      });
  }, [searchParams, userLoading]);

  const loadingContainer = () => (
    <LoadingContainer>
      <PageLoading />
    </LoadingContainer>
  );

  if (loading || userLoading || error !== undefined) {
    return (
      <PageContainer>
        <Header isLoggedIn={true} />
        <LoadingContainer>
          <PageLoading />
        </LoadingContainer>
        {error !== undefined && (
          <Modal
            isOpen={true}
            modalHeading={error.heading}
            modalBody={<Subheading>{error.body}</Subheading>}
            modalButtons={[
              {
                text: 'Back',
                action: () => {
                  navigate('/dashboard');
                },
                buttonTag: RedButton,
              },
            ]}
          />
        )}
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Header isLoggedIn={true} />
      <SubscriptionStatusBanner subscriptionStatus={subscriptionStatus} />
      <ContentWithPaddingXl>
        <Modal
          isOpen={experimentDeleted}
          modalHeading={'Experiment Deleted'}
          modalBody={
            <Subheading>
              Your experiment has been successfully deleted, ensure no webhooks are active with this
              experiment as emails will no longer be sent!
            </Subheading>
          }
          modalButtons={[
            {
              text: 'Home',
              action: () => {
                setExperimentDeleted(false);
                navigate('/dashboard');
              },
              buttonTag: RedButton,
            },
          ]}
        />

        {isEarlyModelOpen && (
          <Modal
            isOpen={isEarlyModelOpen}
            modalButtons={[
              {
                text: 'Close',
                action: () => {
                  setIsEarlyModelOpen(false);
                },
                buttonTag: RedButton,
              },
            ]}
            modalHeading={
              <>
                Warming Up
                <ThreeDotsLoadingContainer>
                  <ThreeDotsLoading />
                </ThreeDotsLoadingContainer>
              </>
            }
            modalBody={
              <InlineText>
                <Subheading>
                  MailBandit experiments can take up to 7 days to begin reacting. Please give your
                  experiment some time to warm up.
                </Subheading>
              </InlineText>
            }
          />
        )}
        <RowContainer className="flex justify-between">
          <DashboardHeading>
            Experiment &nbsp;<HighlightedText>{experiment.name}</HighlightedText>
          </DashboardHeading>
        </RowContainer>
        <MediumHeading>Start Date: {experiment.startDate.toLocaleString('en-GB')}</MediumHeading>
        <StatCards stats={statsCards} />
        <TableStats
          stats={tableStats}
          headers={[
            'Rank',
            'Variant Name',
            metricConfig.labels.table_header,
            'Current Allocation',
            'Emails Sent',
            '',
          ]}
        />
        <ChartContainer>
          <Tabs
            tabs={[
              {
                name: 'Success over time',
                data: successChartData,
                yLabel: metricConfig.labels.success_ylabel,
              },
              {
                name: 'Emails sent over time',
                data: allocationChartData,
                yLabel: metricConfig.labels.allocation_ylabel,
              },
            ]}
            setCurrentTab={setChartTab}
            currentTab={chartTab}
          />
          {chartTab.data === undefined ? (
            loadingContainer()
          ) : (
            <LineChart
              dateLabelsOverTime={dateLabelsOverTime}
              successOverTime={chartTab.data}
              ylabel={chartTab.yLabel}
            />
          )}
        </ChartContainer>
        <ButtonContainer>
          <RedButton onClick={deleteExperiment}>End experiment</RedButton>
        </ButtonContainer>
      </ContentWithPaddingXl>
    </PageContainer>
  );
};
