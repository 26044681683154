import {getSubscriptionStatusStatus} from 'helpers/api/functions/Stripe';
import React from 'react';

const SubscriptionStatus = ({status, message}) => {
  // Get status color based on subscription status
  const getStatusColor = status => {
    switch (status?.toLowerCase()) {
      case 'active':
        return 'bg-green-100 text-green-800 border-green-200';
      case 'trialing':
        return 'bg-blue-100 text-blue-800 border-blue-200';
      case 'canceled':
        return 'bg-red-100 text-red-800 border-red-200';
      case 'incomplete':
        return 'bg-yellow-100 text-yellow-800 border-yellow-200';
      default:
        return 'bg-gray-100 text-gray-800 border-gray-200';
    }
  };

  return (
    <div className="flex items-center space-x-4 my-6">
      {/* Status Badge */}
      <span
        className={`
          inline-flex items-center px-3 py-1 
          rounded-full text-sm font-medium 
          border ${getStatusColor(status)}
          whitespace-nowrap
        `}
      >
        {getSubscriptionStatusStatus(status)}
      </span>

      {/* Status Message */}
      {message && <span className="text-gray-600">{message}</span>}
    </div>
  );
};

export default SubscriptionStatus;
