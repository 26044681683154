import React from 'react';
import tw from 'twin.macro';
import {Container} from './Layouts';
import {DashboardHeading} from './Headings';
import {SectionDescription} from './Typography';
import {OptionsButton} from './Buttons';
import {FaRegCopy} from 'react-icons/fa';

const Overlay = tw.div`
  fixed inset-0 
  bg-gray-500 bg-opacity-75 
  flex items-center justify-center 
  z-50 
  backdrop-blur-sm
`;

const ModalContent = tw.div`
  w-11/12 md:w-1/2 
  bg-white 
  rounded-xl 
  shadow-2xl 
  border border-gray-100 
  overflow-hidden
  max-h-[90vh]

`;

const ModalHeader = tw.div`
pt-6
  px-6
  border-b 
  border-gray-200  
  justify-self-center  text-center
`;

const ModalBody = tw.div`
  p-6 
  overflow-y-auto 
  max-h-[50vh]
`;

const ModalActions = tw.div`
  px-6 py-4 
  flex justify-center 
  space-x-3 
  border-t 
  border-gray-200 
`;

export const Modal = ({
  isOpen,
  modalHeading = 'Some Heading',
  modalBody = 'Please copy and paste the following into your Klaviyo webhook for the experiment to work:',
  modalButtons = [
    {
      text: 'Close',
      action: () => {
        console.log('close clicked');
      },
      buttonTag: OptionsButton,
    },
  ],
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <Container>
      <Overlay onClick={onClose}>
        <ModalContent onClick={e => e.stopPropagation()}>
          <ModalHeader>
            <DashboardHeading className="text-lg font-semibold text-gray-800">
              {modalHeading}
            </DashboardHeading>
          </ModalHeader>
          <ModalBody>
            {typeof modalBody === 'string' ? (
              <SectionDescription>{modalBody}</SectionDescription>
            ) : (
              modalBody
            )}
          </ModalBody>
          <ModalActions>
            {modalButtons.map((mb, index) => (
              <mb.buttonTag
                key={index}
                onClick={mb.action}
                className="px-4 py-2 rounded-md transition-colors"
              >
                {mb.text}
              </mb.buttonTag>
            ))}
          </ModalActions>
        </ModalContent>
      </Overlay>
    </Container>
  );
};

export const ModalCopyLineItem = ({text, iconStates, index, setIconStates}) => {
  const ModalLineItem = tw.div`
    flex items-center justify-between 
    py-2 
    border-b 
    border-gray-200 
    last:border-b-0
  `;

  const CopyButton = tw(FaRegCopy)`
    text-gray-500 
    hover:text-blue-600 
    cursor-pointer 
    transition-colors
  `;

  const handleClick = index => {
    setIconStates(prevStates => {
      const newState = [...prevStates];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <ModalLineItem>
      <SectionDescription className="mr-4 flex-grow ">{text}</SectionDescription>
      {iconStates[index] ? (
        <SectionDescription className="text-green-600 font-semibold">Copied!</SectionDescription>
      ) : (
        <CopyButton
          onClick={() => {
            navigator.clipboard.writeText(text).then(() => {
              handleClick(index);
              setTimeout(() => {
                handleClick(index);
              }, 1000);
            });
          }}
        />
      )}
    </ModalLineItem>
  );
};

export default Modal;
