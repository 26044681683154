import tw from 'twin.macro';

export const Container = tw.div`relative`;
export const PageContainer = tw(Container)`min-h-screen h-full py-10 px-5 mx-auto`;
export const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-20 px-5 mt-4 bg-white`;
export const ContentWithHorizontalPaddingXl = tw.div`max-w-screen-xl mx-auto px-5`;
export const BorderedContentWithPaddingLg = tw.div`max-w-screen-lg mx-auto py-5 lg:py-10 px-5 border-8 border-primary-500 my-5`;
export const ContentWithVerticalPadding = tw.div`py-20 lg:py-24`;
export const ContentWithSlightVerticalPadding = tw.div`py-5 lg:py-10`;

export const Content2Xl = tw.div`max-w-screen-2xl mx-auto`;
