export class Team {
  constructor(
    id,
    name,
    owner,
    members,
    trialEnd,
    isActive,
    subscriptionStatus,
    createdAt = null,
    stripeCustomerId = null,
    subscriptionId = null
  ) {
    this._id = id;
    this._name = name;
    this._owner = owner;
    this._members = members;
    this._trialEnd = trialEnd;
    this._isActive = isActive;
    this._subscriptionStatus = subscriptionStatus;
    this._createdAt = createdAt;
    this._stripeCustomerId = stripeCustomerId;
    this._subscriptionId = subscriptionId;
  }

  // Getters for accessing private properties
  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get owner() {
    return this._owner;
  }

  get members() {
    return this._members;
  }

  get trialEnd() {
    return this._trialEnd;
  }

  get isActive() {
    return this._isActive;
  }

  get subscriptionStatus() {
    return this._subscriptionStatus;
  }

  get createdAt() {
    return this._createdAt;
  }

  get stripeCustomerId() {
    return this._stripeCustomerId;
  }

  get subscriptionId() {
    return this._subscriptionId;
  }
}
