import React from 'react';
import GlobalStyles from 'styles/GlobalStyles';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import ListExperimentsPage from './pages/ListExperimentsPage';
import ViewExperimentDetailsPage from './pages/ViewExperimentDetailsPage';
import CreateExperimentPage from './pages/CreateExperimentPage';
import Login from './pages/Login';
import {PrivateRoute} from './helpers/routing/Routes';
import PricingPage from './pages/PricingPage';
import Signup from './pages/Signup';
import {TourProvider} from './helpers/tour/TourContext';
import SubscriptionManagement from 'pages/SubscriptionManagement';
import SubscriptionSuccess from './pages/SubscriptionSuccess';
import './index.css';

export default function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <TourProvider>
          <Routes>
            <Route path="/dashboard" element={<PrivateRoute component={ListExperimentsPage} />} />
            <Route
              path="/experiment"
              element={<PrivateRoute component={ViewExperimentDetailsPage} />}
            />
            <Route
              path="/create-experiment"
              element={<PrivateRoute component={CreateExperimentPage} />}
            />
            <Route
              path="/my-account"
              element={<PrivateRoute component={SubscriptionManagement} />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/subscription/success"
              element={<PrivateRoute component={SubscriptionSuccess} />}
            />
            <Route path="/*" element={<LandingPage />} />
          </Routes>
        </TourProvider>
      </Router>
    </>
  );
}
